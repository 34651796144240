import makeApiRequest from "./makeApiCall";

export const coaList = async (data) => {
  return await makeApiRequest(`/coa/list`, "POST", null, data, false);
};

export const submitCoa = async (data) => {
  return await makeApiRequest(`/coa/store`, "POST", null, data, false);
};

export const submitCoaDraft = async (data) => {
  return await makeApiRequest(
    `/coa/chart-of-account-draft`,
    "POST",
    null,
    data,
    false
  );
};

export const updateCoa = async (data) => {
  return await makeApiRequest(`/coa/update/status`, "POST", null, data, false);
};

export const updateCoaDraft = async (id, data = null) => {
  return await makeApiRequest(
    `/coa/chart-of-account-draft/${id}`,
    "PUT",
    null,
    data,
    false
  );
};

export const rejectCoaDraft = async (id, data = null) => {
  return await makeApiRequest(
    `/coa/chart-of-account-draft-reject/${id}`,
    "PUT",
    null,
    data,
    false
  );
};

export const deleteCoa = async (data) => {
  return await makeApiRequest(`/coa/soft-delete`, "POST", null, data, false);
};

export const getCoaDetails = async (id) => {
  return await makeApiRequest(`/coa/show/${id}`, "GET", null, null, false);
};

export const getCoaDropdown = async (data) => {
  return await makeApiRequest(`/coa/drop-down`, "POST", null, data, false);
};

export const getCostCenterDropdown = async (data) => {
  return await makeApiRequest(`/coa/drop-down`, "POST", null, data, false);
};

export const toggleActive = async (data) => {
  return await makeApiRequest(`/coa/update/status`, "POST", null, data, false);
};

export const exportCoa = async (data) => {
  return await makeApiRequest(`/coa/gerenate-export`, "GET", null, data, false);
};

export const getCashinBackDropdown = async (data) => {
  return await makeApiRequest(
    `/coa/list/cash-in-bank/dropdown`,
    "GET",
    null,
    data,
    false
  );
};
