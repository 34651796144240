import { useEffect, useState, Fragment } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

//components
import PrimaryDataTable from '../../../../components/PrimaryDataTable';
import FormModal from './FormModal';
import DetailModal from './DetailModal';

// api
import {
  deleteFootprintTracing,
  getFootprintTracing,
  postFootprintTracing,
} from '../../../../api/collections';

// consts
import { footprintTracingColumns } from './const';
import {
  hasPermission,
  PermissionsGate,
} from '../../../../components/PermissionsGate';

const FootprintTracing = ({ customer_code }) => {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formModal, setFormModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [detailModal, setDetailModal] = useState({
    toggle: false,
    data: null,
  });

  useEffect(() => loadData(customer_code), [customer_code]);

  const loadData = (customerCode) => {
    if (customerCode === '') return;

    setLoading(true);
    setData([]);

    getFootprintTracing(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;

      if (code === '00000') {
        let editCustomerFootPrintPermission = hasPermission([
          'edit_customer_footprint',
        ]);

        setData(
          result.map((row, index) => ({
            ...row,
            id: customerCode + '_' + index,
            editCustomerFootPrintPermission,
          })),
        );
      }
    });
  };

  const handleFootprintModal = () => setFormModal(true);

  const handleFormSubmit = async (form) => {
    let { isConfirmed } = await Swal.fire({
      title: 'Footprint Tracing',
      html: 'Are you sure you want to save this?',
      icon: 'warning',
      showCancelButton: true,
    });

    if (isConfirmed) {
      toast.loading('Please wait saving ...');
      setFormLoading(true);

      let res = await postFootprintTracing({ ...form, customer_code }, true);

      const { code, result } = res.data.response;

      toast.dismiss();
      setFormLoading(false);

      let { isConfirmed } = await Swal.fire({
        title: 'Footprint Tracing',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code === '00000' ? 'success' : 'error',
      });

      if (isConfirmed && code === '00000') {
        setFormModal(false);
        // load data
        loadData(customer_code);
      }
    }
  };

  const handleViewDetail = (row) => {
    setDetailModal({ toggle: true, data: row });
    return false;
  };

  const handleDelete = async () => {
    if (!selectedIds.length) return;

    let { isConfirmed } = await Swal.fire({
      title: 'Footprint Tracing',
      html: 'Are you sure you want to delete this record(s)?',
      icon: 'warning',
      showCancelButton: true,
    });

    if (isConfirmed) {
      toast.loading('Please wait ...');

      let res = await deleteFootprintTracing(selectedIds);

      const { code, result } = res.data.response;
      toast.dismiss();

      let { isConfirmed } = await Swal.fire({
        title: 'Footprint Tracing',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code === '00000' ? 'success' : 'error',
      });

      if (isConfirmed) {
        loadData(customer_code);
      }
    }
  };

  const columns = footprintTracingColumns({
    handleViewDetail: (row) => handleViewDetail(row),
  });

  return (
    <Fragment>
      <div className="text-right mb-3">
        <PermissionsGate scopes={['create_collection_footprint']}>
          <Button variant="success" onClick={handleFootprintModal}>
            Add Footprint
          </Button>
        </PermissionsGate>
        &nbsp;
      </div>
      <div className="mb-3">
        <PrimaryDataTable
          rows={data}
          columns={columns}
          disableColumnSelector={true}
          getRowId={(row) => row.footprint_id}
          checkboxSelection
          hasGridToolbar
          onSelectionModelChange={setSelectedIds}
          loading={loading}
        />
      </div>
      <Row>
        <Col>
          <PermissionsGate scopes={['delete_customer_footprint']}>
            <Button
              variant="danger"
              disabled={!selectedIds.length}
              onClick={handleDelete}
            >
              Delete Selected
            </Button>
          </PermissionsGate>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <FormModal
        toggle={formModal}
        closeModal={() => setFormModal(false)}
        onSubmit={handleFormSubmit}
        loading={formLoading}
      />
      <DetailModal
        toggle={detailModal.toggle}
        data={detailModal.data}
        closeModal={() => setDetailModal({ toggle: false, data: null })}
      />
    </Fragment>
  );
};

export default FootprintTracing;
