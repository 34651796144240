import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import Select from 'react-select';

export default function UpdateModal(props) {
  const {
    show,
    setForm,
    form,
    userDropdown,
    handleUpdate,
    moduleDropdown,
    coaDropdown,
    handleClose,
    setIsUpdate,
  } = props;

  const handleChangeUser = (e) => {
    setForm({ ...form, user_id: e.value });
  };

  const handleChangeCoa = (e) => {
    setForm({ ...form, account_code: e.value });
  };

  const handleChangeModule = (e) => {
    setIsUpdate(true);
    //
    if (form.account_code === '') {
      setForm({
        ...form,
        module_id: e.value,
        old_account_code: form.account_code,
      });
    } else {
      setForm({
        ...form,
        module_id: e.value,
        old_account_code: form.account_code,
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        //
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update COA Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formUser"
              size="md"
            >
              <Form.Label column md="2">
                User
              </Form.Label>
              <Col md="10">
                <Select
                  name="user_id"
                  onChange={handleChangeUser}
                  value={
                    form.user_id
                      ? userDropdown
                          .filter((row) => row.roles.length === 0)
                          .find((row) => row.value === form.user_id)
                      : ''
                  }
                  options={userDropdown.filter((row) => row.roles.length === 0)}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formModule"
              size="md"
            >
              <Form.Label column md="2">
                Module
              </Form.Label>
              <Col md="10">
                <Select
                  name="module_id"
                  onChange={handleChangeModule}
                  value={
                    form.module_id
                      ? moduleDropdown.find(
                          (row) => row.value === form.module_id,
                        )
                      : ''
                  }
                  options={moduleDropdown}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCoa" size="md">
              <Form.Label column md="2">
                COA
              </Form.Label>
              <Col md="10">
                <Select
                  name="account_code"
                  onChange={handleChangeCoa}
                  value={
                    form.account_code
                      ? coaDropdown.find(
                          (row) =>
                            parseInt(row.value) === parseInt(form.account_code),
                        )
                      : /**/ form.old_account_code
                  }
                  options={coaDropdown}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
