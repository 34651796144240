import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import CreateModal from './components/CreateModal';
import { getUsers } from '../../../api/users';
import {
  createPermissionAccount,
  deletePermissionAccount,
  getModules,
  getPermissionAccount,
  updatePermissionAccount,
} from '../../../api/permission';
import { permissionAccountColumns } from './const';
import { coaList } from '../../../api/chartOfAccount';
import defaultOptions from '../../../utils/defaultOptions';
import { toast } from 'react-toastify';
//import PrimaryDataTable from '../../PrimaryDataTable';
import PrimaryDataTable from '../../../components/PrimaryDataTable';
import Swal from 'sweetalert2';
import UpdateModal from './components/UpdateModel';
import { getJournalVoucherCoaTags } from '../../../api/journalVoucher';
import { getCOAList } from '../../../api/payableVoucher';

function useUsers() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [userDropdown, setUserDropdown] = useState([]);

  useEffect(() => {
    getUsers()
      .then((res) => {
        const { code, result } = res.data.response;
        setUsers(result);

        setUserDropdown(
          result.map((row) => ({
            value: row.user_id,
            label: row.username,
            roles: row?.roles.filter((roles) =>
              roles?.role === 'Admin' ? roles?.role : '',
            ),
            row,
          })),
        );

        return () => {};
      })
      .catch((e) => {
        console.error(`Critical failure: ${e.message}`);
      });
  }, []);

  return { userDropdown, users, selectedUser, setSelectedUser };
}

function useModules() {
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState('');
  const [moduleDropdown, setModuleDropdown] = useState([]);

  useEffect(() => {
    getModules()
      .then((res) => {
        const { code, result } = res.data.response;

        const output = result.filter((rows) => {
          if (rows.module === 'payable_voucher') {
            return rows;
          } else if (rows.module === 'journal_voucher') {
            return rows;
          }
          //else if (rows.module === 'debit_memo') {
          //   return rows;
          // }
        });

        setModules(output);

        const outputs = result.filter((rows) => {
          if (rows.module === 'payable_voucher') {
            return rows;
          } else if (rows.module === 'journal_voucher') {
            return rows;
          }
          // else if (rows.module === 'debit_memo') {
          //   return rows;
          // }
        });

        setModuleDropdown(
          outputs.map((row) => ({
            value: row.module_id,
            label: row.label,
            row,
          })),
        );

        return () => {};
      })
      .catch((e) => {
        console.error(`Critical failure: ${e.message}`);
      });
  }, []);

  return { moduleDropdown, modules, selectedModules, setSelectedModules };
}

const formDefault = {
  user_id: '',
  module_id: '',
  account_code: '',
  page: 0,
  page_size: 50,
  direction: 'asc',
  sortModel: [],
  filterModel: {
    items: [],
  },
};

export default function CoaPermissions() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { userDropdown } = useUsers();
  const { moduleDropdown } = useModules();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(formDefault);
  const [tableData, setTableData] = useState({
    data: [],
    loading: false,
    totalRows: 0,
  });

  const [coaDropdown, setCoaDropdown] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    loadData(form);
  }, []);

  const coaLists = () => {
    let param = {
      page: 0,
      pageSize: defaultOptions?.pageSize,
      sortModel: [],
      filterModel: { items: [] },
    };

    coaList(param)
      .then((res) => {
        const { code, result } = res?.data?.response;
        setCoaDropdown(
          result.map((row) => ({
            value: row.account_code,
            label: row.account_name,
            row,
          })),
        );

        return () => {};
      })
      .catch((e) => {
        console.error(`Critical failure: ${e.message}`);
      });
  };

  const coaPvList = () => {
    getCOAList({ search: '' })
      .then((res) => {
        const { code, result } = res?.data?.response;
        setCoaDropdown(
          result?.map((row) => ({
            value: row?.account_code,
            label: row?.account_name,
            row,
          })),
        );

        return () => {};
      })
      .catch((e) => {
        console.error(`Critical failure: ${e.message}`);
      });
  };

  const coaJvList = () => {
    getJournalVoucherCoaTags()
      .then((res) => {
        const { code, result } = res?.data?.response;

        setCoaDropdown(
          result?.map((row) => ({
            value: row?.account_code,
            label: row?.account_name,
            row,
          })),
        );

        return () => {};
      })
      .catch((e) => {
        console.error(`Critical failure: ${e.message}`);
      });
  };

  const handleCreate = async () => {
    const tload = toast.loading('Please wait saving ...');
    setLoading(true);
    try {
      const res = await createPermissionAccount(form);
      const { code, result } = res.data.response;
      setLoading(false);
      toast.dismiss(tload);
      await Swal.fire({
        title: 'COA Permission',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code !== '00003' ? 'error' : 'success',
      });
      if (code === '00003') {
        setShowCreateModal(false);
        loadData(form);
      }
    } catch (error) {
      toast.dismiss(tload);
      setLoading(false);

      toast.error('Unable to save data.');
    }
  };

  const loadData = async (params_) => {
    setTableData({ ...tableData, loading: true });
    try {
      console.log('sdsda', params_);
      const res = await getPermissionAccount(params_);

      const { total_rows, code, result } = res.data.response;

      if (code !== '00000') {
        setTableData({
          ...tableData,
          data: [],
          loading: false,
          totalRows: 0,
        });
        return;
      }

      let permissionAccountList = result;

      setTableData({
        ...tableData,
        loading: false,
        data: permissionAccountList,
        totalRows: total_rows,
      });
    } catch (error) {
      setTableData({
        ...tableData,
        data: [],
        loading: false,
        totalRows: 0,
      });
      toast.error('Unable to load clear data.');
    }
  };

  const handleViewDetail = (row) => {
    setIsCreate(false);
    setShowUpdateModal(true);

    setForm({
      account_code: row.account_code,
      user_id: row.user_id,
      module_id: row.module_id,
      id: row.id,
      page: form.page,
    });
    console.log('form view', form);
  };

  const handleDeleteDetail = async (row) => {
    const tload = toast.loading('Please wait deleting ...');
    setLoading(true);
    try {
      const Id = {
        id: row.id,
      };
      const res = await deletePermissionAccount(Id);

      const { code, result } = res.data.response;
      setLoading(false);
      toast.dismiss(tload);
      await Swal.fire({
        title: 'COA Permission',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code !== '00003' ? 'error' : 'success',
      });
      if (code === '00003') {
        setShowCreateModal(false);
        loadData(form);
      }
    } catch (error) {
      toast.dismiss(tload);
      setLoading(false);

      toast.error('Unable to delete data.');
    }
  };

  const columns = permissionAccountColumns({
    handleViewDetail: (row) => handleViewDetail(row),
    handleDeleteDetail: (row) => handleDeleteDetail(row),
  });

  const handleUpdate = async () => {
    const tload = toast.loading('Please wait updating ...');
    setLoading(true);
    try {
      const res = await updatePermissionAccount(form);

      const { code, result } = res.data.response;
      setLoading(false);
      toast.dismiss(tload);
      await Swal.fire({
        title: 'COA Permission',
        html: Array.isArray(result) ? result.join('<br />') : result,
        icon: code !== '00003' ? 'error' : 'success',
      });
      if (code === '00003') {
        setShowUpdateModal(false);
        loadData(form);
      }
    } catch (error) {
      toast.dismiss(tload);
      setLoading(false);

      toast.error('Unable to update data.');
    }
  };

  const handleClose = () => {
    setShowUpdateModal(false);

    // console.log('cancel', form);

    // setForm((prev) => {
    //   console.log('prev', prev);
    //   // return {
    //   //   ...prev,
    //   //   module_id: prev.module_id,
    //   //   page: form?.page,
    //   //   account_code: form.account_code,
    //   // };
    // });
  };

  const handleCloseSave = () => {
    setShowCreateModal(false);
    setForm({
      ...form,
      user_id: '',
      module_id: '',
      account_code: '',
      page: form.page,
    });
  };

  const addNewPermission = () => {
    setIsCreate(true);
    setShowCreateModal(true);
    setForm({
      ...form,
      user_id: '',
      module_id: '',
      account_code: '',
      page: form.page,
    });
  };

  useEffect(() => {
    if (isCreate || isUpdate) {
      setForm({ ...form, account_code: '' });
    }

    //JV
    if (form.module_id == 98) {
      coaJvList();
    }

    // //DM
    // if (form.module_id == 100) {
    //   coaLists();
    // }

    //PV
    if (form.module_id == 99) {
      coaPvList();
    }
  }, [form.module_id]);

  const handleDataTableChange = (params) => {
    let newFormData = {
      ...form,
      ...params,
      page: params.page,
    };
    setForm({ ...form, page: params.page });
    loadData(newFormData);
    console.log('form next', form);
  };

  const handleFilterModelChange = (filterModel) => {
    console.log('params', filterModel);
    const newFormData = {
      ...form,
      filterModel,
    };
    loadData(newFormData);
  };

  // const handleTableChange = (params) => {
  //   const newParams = { ...form, ...params };

  //   loadData(newParams);
  // };

  return (
    <>
      <Row className="mb-3">
        <Col md="6">
          <h2 className="page-title">COA Permissions</h2>
        </Col>
        <Col md="6">
          <div className="text-right">
            <Button
              variant="primary"
              size="md"
              active
              onClick={addNewPermission}
            >
              <PlusCircleFill /> New Permission
            </Button>
            <CreateModal
              show={showCreateModal}
              form={form}
              setForm={setForm}
              userDropdown={userDropdown}
              handleCreate={handleCreate}
              coaDropdown={coaDropdown}
              moduleDropdown={moduleDropdown}
              handleClose={handleCloseSave}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <PrimaryDataTable
          columns={columns}
          rows={tableData.data}
          loading={tableData.loading}
          getRowId={(row) => row.id}
          filterMode="server"
          mode="server"
          onFilterModelChange={handleFilterModelChange}
          onChange={handleDataTableChange}
          //   handleTableChange={handleTableChange}
          rowCount={tableData.totalRows}
        />
        <UpdateModal
          show={showUpdateModal}
          form={form}
          setForm={setForm}
          userDropdown={userDropdown}
          handleUpdate={handleUpdate}
          coaDropdown={coaDropdown}
          moduleDropdown={moduleDropdown}
          handleClose={handleClose}
          setIsUpdate={setIsUpdate}
        />
      </Row>
    </>
  );
}
