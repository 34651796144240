import { Button } from 'react-bootstrap';
import { PencilSquare, Trash2 } from 'react-bootstrap-icons';

export const permissionAccountColumns = ({
  handleViewDetail,
  handleDeleteDetail,
}) => {
  return [
    {
      headerName: 'Username',
      field: 'username',
      minWidth: 300,
    },
    {
      headerName: 'Account Code',
      field: 'account_code',
      minWidth: 250,
    },
    {
      headerName: 'Module',
      field: 'label',
      minWidth: 400,
    },
    {
      headerName: 'Account Name',
      field: 'account_name',
      minWidth: 500,
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 400,
      renderCell: ({ row }) => (
        <>
          <Button
            size="sm"
            onClick={() => handleViewDetail(row)}
            className="btn btn-warning"
          >
            <PencilSquare />
          </Button>
          &nbsp;
          <Button
            size="sm"
            onClick={() => handleDeleteDetail(row)}
            className="btn btn-danger"
          >
            <Trash2 />
          </Button>
        </>
      ),
    },
  ];
};
