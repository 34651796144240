import makeApiRequest from './makeApiCall';

export const getModules = async (form) => {
  return await makeApiRequest(`/modules`, 'GET', null, form);
};

export const getPermissionPerID = async (id) => {
  return await makeApiRequest(
    `/permission/module/` + id + `/all-permissions`,
    'GET',
    null,
    null,
    false,
  );
};

export const grantPermission = async (data) => {
  return makeApiRequest('/permissions/revoke', 'PUT', null, data);
};

export const revokePermission = async (data) => {
  return makeApiRequest('/permissions/grant', 'PUT', null, data);
};

export const createPermissionInSideModule = async (data) => {
  return await makeApiRequest(`/permission/create`, 'POST', null, data);
};

export const createPermission = async (data) => {
  return makeApiRequest('/permissions/fields/create', 'POST', null, data);
};

export const getPermissionAccount = async (data) => {
  return await makeApiRequest(`/permission/accounts`, 'POST', null, data);
};

export const updatePermissionAccount = async (data) => {
  return await makeApiRequest(
    `/permission/accounts/update`,
    'POST',
    null,
    data,
  );
};

export const createPermissionAccount = async (data) => {
  return await makeApiRequest(
    `/permission/accounts/create`,
    'POST',
    null,
    data,
  );
};

export const deletePermissionAccount = async (data) => {
  return await makeApiRequest(
    `/permission/accounts/delete`,
    'POST',
    null,
    data,
  );
};
