import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import Select from 'react-select';

export default function CreateModal(props) {
  const {
    show,
    setForm,
    form,
    userDropdown,
    handleCreate,
    moduleDropdown,
    coaDropdown,
    handleClose,
  } = props;

  const handleChangeUser = (e) => {
    setForm({ ...form, user_id: e.value });
  };

  const handleChangeCoa = (e) => {
    setForm({ ...form, account_code: e.value });
  };

  const handleChangeModule = (e) => {
    setForm({ ...form, module_id: e.value });
  };

  return (
    <>
      <Modal
        show={show}
        //
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>New COA Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formUser"
              size="md"
            >
              <Form.Label column md="2">
                User
              </Form.Label>
              <Col md="10">
                <Select
                  name="user_id"
                  onChange={handleChangeUser}
                  value={
                    form.user_id
                      ? userDropdown
                          .filter((row) => row.roles.length === 0)
                          .find((row) => row.value === form.user_id)
                      : ''
                  }
                  options={userDropdown.filter((row) => row.roles.length === 0)}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formModule"
              size="md"
            >
              <Form.Label column md="2">
                Module
              </Form.Label>
              <Col md="10">
                <Select
                  name="module_id"
                  onChange={handleChangeModule}
                  value={
                    form.module_id
                      ? moduleDropdown.find(
                          (row) => row.value === form.module_id,
                        )
                      : ''
                  }
                  options={moduleDropdown}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCoa" size="md">
              <Form.Label column md="2">
                COA
              </Form.Label>
              <Col md="10">
                <Select
                  name="account_code"
                  onChange={handleChangeCoa}
                  value={
                    form.account_code
                      ? coaDropdown.find(
                          (row) => row.value === form.account_code,
                        )
                      : ''
                  }
                  options={coaDropdown}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {console.log('form', form)}
          <Button
            variant="primary"
            onClick={handleCreate}
            disabled={!form.user_id || !form.module_id || !form.account_code}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
// import React, { useState } from 'react';

// export default function CreateModal(props) {
//   const {
//     show,
//     handleClose,
//     users,
//     setSelectedUser,
//     modules,
//     setSelectedModules,
//     coa,
//     setSelectedCoa,
//     handleCreate,
//   } = props;

//   function handleChangeUsers(e) {
//     setSelectedUser(e.target.value);
//   }

//   function handleChangeModules(e) {
//     setSelectedModules(e.target.value);
//   }

//   function handleChangeCoa(e) {
//     setSelectedCoa(e.target.value);
//   }

//   return (
//     <>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>New COA Permission</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group
//               as={Row}
//               className="mb-3"
//               controlId="formUser"
//               size="md"
//             >
//               <Form.Label column md="2">
//                 User
//               </Form.Label>
//               <Col md="10">
//                 <Form.Select size="md" onChange={handleChangeUsers}>
//                   {users.map((user) => (
//                     <>
//                       <option key={user.user_id} value={user.user_id}>
//                         {user.username}
//                       </option>
//                     </>
//                   ))}
//                 </Form.Select>
//               </Col>
//             </Form.Group>

//             <Form.Group
//               as={Row}
//               className="mb-3"
//               controlId="formModule"
//               size="md"
//             >
//               <Form.Label column md="2">
//                 Module
//               </Form.Label>
//               <Col sm="10">
//                 <Form.Select size="md" onChange={handleChangeModules}>
//                   {modules.map((module) => {
//                     return (
//                       <option key={module.module_id} value={module.module_id}>
//                         {module.label}
//                       </option>
//                     );
//                   })}
//                 </Form.Select>
//               </Col>
//             </Form.Group>

//             <Form.Group
//               as={Row}
//               className="mb-3"
//               controlId="formModule"
//               size="md"
//             >
//               <Form.Label column md="2">
//                 COA
//               </Form.Label>
//               <Col sm="10">
//                 <Form.Select size="md" onChange={handleChangeCoa}>
//                   {coa.map((c) => {
//                     return (
//                       <option key={c.id} value={c.account_code}>
//                         {c.account_name}
//                       </option>
//                     );
//                   })}
//                 </Form.Select>
//               </Col>
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleCreate}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
